import React from "react";
import {StaticImage} from "gatsby-plugin-image";
import {wrapper, edge} from './index.module.scss';

const Body = ({children}) => {

  return (
    <div className={wrapper} id="body-wrapper">
      <StaticImage className={edge} src="../../images/edge-header.png" alt="edge" layout="fullWidth" />
      <div>
        {children}
      </div>
    </div>
  )
  
}

export default Body;