import React from "react";
import Section from "../Section";
import {wrapper, text, image} from './index.module.scss';
import {StaticImage} from "gatsby-plugin-image";

const Descripcion2 = () => {

  return (
    <div className={wrapper} id="desc2">
      <Section>
        <StaticImage className={image} src="../../images/bgrd-desc2.png" alt="rio-back" />
        <div className={text}>
          De frente al Rio y a la inmensidad de la Naturaleza, vamos a hacer CASA JAGUAR.
          Viviendas, hotelería, Eco-Park, un paseo comercial y Gastronómico.<br/> <b>El futuro es ahora.</b>
          <br/>
          <br/>
          CASA JAGUAR mezcla las comodidades del mundo moderno, con la sabiduría de nuestros ancestros, en un disfrute
          pleno y sustentable con la naturaleza.
        </div>
      </Section>
    </div>
  )

}

export default Descripcion2;