import React from "react";
import {Parallax} from "react-parallax";
import headerImage from '../../images/header-hero.jpg';
import {insideStyles, textWrapper, mobileWrapper, desktopWrapper, image} from './index.module.scss';
import {StaticImage} from "gatsby-plugin-image";


const HeaderText = () =>
  <div className={textWrapper}>
    <div className={insideStyles}>
      <h1>CASA JAGUAR</h1>
      <p> “LA VIDA ES AHORA” </p>
    </div>
  </div>;

const Header = () => {
  const {matches: isMobile} = typeof window !== 'undefined' ? window.matchMedia('(max-width: 767px)') : {matches: false};

  if (isMobile) {
    return (
      <div className={mobileWrapper}>
        <StaticImage className={image} src="../../images/header-hero.jpg" alt="portada" layout="fullWidth" aspectRatio={9 / 16}/>
        <HeaderText/>
      </div>
    );
  }

  return (
    <div className={desktopWrapper}>
      <Parallax bgImage={headerImage} bgImageStyle={{bottom: 0}} strength={500} style={{width: '100%'}}>
        <HeaderText/>
      </Parallax>
    </div>
  )

}

export default Header;