import React from "react";
import {wrapper, edge} from './index.module.scss';
import {StaticImage} from "gatsby-plugin-image";

const Footer = () => {


  return (
      <div className={wrapper}>
        <StaticImage className={edge} src="../../images/edge-footer.png" alt="edge" layout="fullWidth" />
        <h1>CASA JAGUAR</h1>
        <p>Copyright © 2022 CASA JAGUAR. Todos los derechos reservados.</p>
      </div>
  )

}

export default Footer;