import React from "react";
import backgroundImage from "../../images/bgrd-casa-jaguar.jpg";
import {wrapper, preText} from './index.module.scss';

const Proyecto1 = () => {

  const bgrdStyles =   {
    background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${backgroundImage})`,
    backgroundPositionY: '-260px'
  };

  return (
    <div className={wrapper} style={bgrdStyles}>
      <p className={preText}>
        Un barrio Privado Náutico, pensado al detalle y en un entorno privilegiado con el Rio como testigo de la Vida.
        Con Puerto Único y salida exclusiva al Rio Lujan. Todos los lotes cuentan con amarra propia. Un rincón natural
        y preservado, con forestación autóctona, paisajismo de diseño, Eco-Lodge, paseo comercial y propuestas
        gastronómicas.
      </p>
      <ul>
        <li>Proyecto de 60.000 m2 y xx lotes de entre xx y xx mts2.</li>
        <li>Amarras propias para embarcaciones menores.</li>
        <li>Embarcadero para amarre de embarcaciones mayores.</li>
        <li>Espacios verdes.</li>
        <li>Canchas de tenis.</li>
        <li>Todos los servicios subterráneos de Luz, Gas, Agua, Cloacas y Fibra Optica.</li>
        <li>Acceso privado, de servicio y seguridad integrada en todo el Masterplan.</li>
        <li>Calles asfaltadas y una increíble forestación autóctona.</li>
      </ul>
    </div>
  )

}

export default Proyecto1;