import React from "react";
import backgroundImage from "../../images/bgrd-delta-jaguar-lodge.jpg";
import {wrapper, preText} from './index.module.scss';

const Proyecto2 = () => {

  const bgrdStyles =   {
    background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${backgroundImage})`,
    backgroundPositionY: '-260px'
  };

  return (
    <div className={wrapper} style={bgrdStyles}>
      <p className={preText}>
        Un espacio Unico para experimentar uno de los más exclusivos hábitats de nuestro país, inmerso en uno de los
        ecosistemas más ricos y desconocidos por el cual se extiende un humedal que alberga un paraíso salvaje que lo
        rodea y que a su vez, cumple un rol importante como el mayor reservorio de biodiversidad. Cada alojamiento
        está integrado al humedal mismo, permitiendo una conexión con la naturaleza autentica y sensible. Con Jacuzzi
        privado y amenities exclusivos, en el verdadero corazón del Delta.
      </p>
      <ul>
        <li>7.300 Mts2.</li>
        <li>Xx Lodges de entre 30 y 50 m2 con balcón propio sobre el humedal.</li>
        <li>Jacuzzi y Fire Pit privado en cada Lodge.</li>
        <li>Pileta y Jacuzzi de espacios comunes.</li>
        <li>Embarcadero con Acceso Propio y salida exclusiva al Rio Lujan.</li>
      </ul>
    </div>
  )

}

export default Proyecto2;