import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import {carousel} from './index.module.scss';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const Carousel = ({children}) => {
  const getSlides = () => {
    if (Array.isArray(children)) {
      return children.map((child, i) => <SwiperSlide key={`slide-${i}`}>{child}</SwiperSlide>);
    }

    return <SwiperSlide key={`slide-1`}>{children}</SwiperSlide>
  }

  return (
    <Swiper
      modules={[Navigation, Pagination]}
      spaceBetween={50}
      navigation
      pagination={{ clickable: true }}
      className={carousel}
    >
      {getSlides()}
    </Swiper>
  );
};

export default Carousel;