import React, {useState} from 'react';
import Tab from "./Tab";
import Carousel from '../Carousel';
import {StaticImage} from "gatsby-plugin-image";
import Proyecto1 from "../Proyecto/Proyecto1";
import Proyecto2 from "../Proyecto/Proyecto2";
import Proyecto3 from "../Proyecto/Proyecto3";
import Proyecto4 from "../Proyecto/Proyecto4";
import {wrapper, tabsWrapper} from './index.module.scss';

const Tabs = () => {
  const [selected, setSelected] = useState(1);

  return (
    <div className={wrapper}>
      <div className={tabsWrapper}>
        <Tab number={1} title="Casa Jaguar" subtitle="Barrio privado" onClick={setSelected} selectedTab={selected}
             color="#89A19B"/>
        <Tab number={2} title="Delta Jaguar Lodge" subtitle="Eco-Lodge & SPA" onClick={setSelected}
             selectedTab={selected}
             color="#AB6980"/>
        <Tab number={3} title="Galerias Jaguar" subtitle="Punto de encuentro" onClick={setSelected} selectedTab={selected}
             color="#CC685A"/>
        <Tab number={4} title="Casa Jaguar" subtitle="Departamentos" onClick={setSelected} selectedTab={selected}
             color="#597894"/>
      </div>
      <div>
        {selected === 1 &&
        <>
          <Carousel>
            <StaticImage src="../../images/carousel_1_1.jpg" alt="master11" layout="fullWidth" placeholder="blurred" aspectRatio={16/8} />
            <StaticImage src="../../images/carousel_1_2.jpg" alt="master12" layout="fullWidth" placeholder="blurred" aspectRatio={16/8} />
            <StaticImage src="../../images/carousel_1_3.jpg" alt="master13" layout="fullWidth" placeholder="blurred" aspectRatio={16/8} />
            <StaticImage src="../../images/carousel_1_4.jpg" alt="master14" layout="fullWidth" placeholder="blurred" aspectRatio={16/8} />
          </Carousel>
          <Proyecto1 />
        </>
        }
        {selected === 2 &&
        <>
          <Carousel>
            <StaticImage src="../../images/carousel_2_1.jpg" alt="master21" layout="fullWidth" placeholder="blurred" aspectRatio={16/8} />
            <StaticImage src="../../images/carousel_2_2.jpg" alt="master22" layout="fullWidth" placeholder="blurred" aspectRatio={16/8} />
            <StaticImage src="../../images/carousel_2_3.jpg" alt="master23" layout="fullWidth" placeholder="blurred" aspectRatio={16/8} />
            <StaticImage src="../../images/carousel_2_4.jpg" alt="master24" layout="fullWidth" placeholder="blurred" aspectRatio={16/8} />
            <StaticImage src="../../images/carousel_2_5.jpg" alt="master25" layout="fullWidth" placeholder="blurred" aspectRatio={16/8} />
          </Carousel>
          <Proyecto2 />
        </>
        }
        {selected === 3 &&
        <>
          <Carousel>
            <StaticImage src="../../images/carousel_3_1.jpg" alt="master31" layout="fullWidth" placeholder="blurred" aspectRatio={16/8} />
            <StaticImage src="../../images/carousel_3_2.jpg" alt="master32" layout="fullWidth" placeholder="blurred" aspectRatio={16/8} />
            <StaticImage src="../../images/carousel_3_3.jpg" alt="master33" layout="fullWidth" placeholder="blurred" aspectRatio={16/8} />
            <StaticImage src="../../images/carousel_3_4.jpg" alt="master34" layout="fullWidth" placeholder="blurred" aspectRatio={16/8} />
          </Carousel>
          <Proyecto3 />
        </>
        }
        {selected === 4 &&
        <>
          <Carousel>
            <StaticImage src="../../images/carousel_4_1.jpg" alt="master41" layout="fullWidth" placeholder="blurred" aspectRatio={16/8} />
          </Carousel>
          <Proyecto4 />
        </>
        }
      </div>
    </div>
  );
};

export default Tabs;