import React from "react";
import {wrapper} from './index.module.scss';

const Section = ({children}) => {

  return (
    <div className={wrapper}>
      {children}
    </div>
  )
  
}

export default Section;