import React, {useState} from 'react';
import {wrapper, numberBox, titleBox, subtitleBox, dash} from './index.module.scss';

const Tab = ({onClick, number, title, subtitle, selectedTab, color}) => {
  const isSelected = number === selectedTab;
  const [isHover, setIsHover] = useState(false);

  const boxStyle = { color: isHover || isSelected ? color : '#736D23' };
  const dashStyle = { backgroundColor: isHover || isSelected ? color : 'transparent' };

  return (
    <div
      className={wrapper}
      style={boxStyle}
      onClick={() => onClick(number)}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <div className={numberBox}>{number}</div>
      <div className={titleBox}>{title}</div>
      <div className={subtitleBox}>{subtitle}</div>
      <div className={dash} style={dashStyle} />
    </div>
  );
};

export default Tab;