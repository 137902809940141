import React from "react";
import backgroundImage from "../../images/bgrd-galerias.jpg";
import {wrapper, preText} from './index.module.scss';

const Proyecto3 = () => {

  const bgrdStyles =   {
    background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${backgroundImage})`,
    backgroundPositionY: '-260px'
  };

  return (
    <div className={wrapper} style={bgrdStyles}>
      <p className={preText}>
        El paseo comercial se posiciona como un centro de buenos sabores, entretenimiento, compras y salud, con
        propuestas de nivel y variadas, que se congregan en una construcción de diseño exquisito. Los detalles de gran
        sensibilidad estilística generan sensaciones únicas.
      </p>
      <ul>
        <li>11.200 m2</li>
        <li>1.500 a 3.000 m2 de espacios gastronómicos, oficinas y entretenimientos.</li>
        <li>Parking para xx autos.</li>
      </ul>
    </div>
  )

}

export default Proyecto3;