import React from "react";
import {StaticImage} from "gatsby-plugin-image";
import Section from "../Section";
import Tabs from "../Tabs";
import {wrapper, supWrapper, sup, supLabel, edge} from './index.module.scss';

const MasterPlan = () => {

  return (
    <>
      <div className={wrapper}>
        <Section>
          <h2>MASTERPLAN</h2>
          <div>
            <StaticImage src="../../images/masterplan.png" alt="master" layout="fullWidth"/>
          </div>
          <div className={supWrapper}>
            <p className={sup}>93.600 m2</p>
            <p className={supLabel}>SUPERFICIE TOTAL</p>
          </div>
        </Section>
      </div>
      <StaticImage className={edge} src="../../images/edge-mp.png" alt="edge" layout="fullWidth"/>
      <Section>
        <Tabs/>
      </Section>
    </>
  )

}

export default MasterPlan;