import React from "react";
import backgroundImage from "../../images/bgrd-casa-jaguar-dtos.jpg";
import {wrapper, preText} from './index.module.scss';

const Proyecto4 = () => {

  const bgrdStyles =   {
    background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${backgroundImage})`,
    backgroundPositionY: '-260px'
  };

  return (
    <div className={wrapper} style={bgrdStyles}>
      <p className={preText}>
        Unidades de xx a xx m2 para poder disfrutar de la vida en el Delta del Paraná. Tu Departamento en pleno Bosque de Galería Fluvial con Vista al Rio, terraza privada y amenities de primera. Viví sobre el Rio con árboles Nativos, canales, Eco-Lodge y Paseo Gastronómico.
      </p>
      <ul>
        <li>Desarrollado en un espacio de 2500 m2.</li>
        <li>Dos edificios de Propiedad Horizontal.</li>
        <li>Xx Departamentos entre xx y xx m2.</li>
        <li>Balcones aterrazados con vistas al Bosque, a los canales y al Rio.</li>
        <li>Imponentes terrazas privadas, con Fire Pit, Kamados y Vistas al Delta.</li>
        <li>Piscina y Solarium.</li>
        <li>Gym.</li>
        <li>Ascensor.</li>
        <li>Estacionamiento Privado y de Cortesía.</li>
      </ul>
    </div>
  )

}

export default Proyecto4;