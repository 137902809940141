import React, {useEffect, useState} from "react"
import Header from "../components/Header";
import Body from "../components/Body";
import MasterPlan from "../components/MasterPlan";
import Descripcion from "../components/Descripcion";
import Descripcion2 from "../components/Descripcion2";
import Footer from "../components/Footer";
import Overlay from "../components/Overlay";
import '../styles/global.css';

const IndexPage = () => {
  const [loading, setLoading] = useState(true);
  const [showOverlay, setShowOverlay] = useState(true);

  useEffect(() => {
    document?.body.style.setProperty('overflow', 'hidden');
    setTimeout(() => {
      document?.body.style.setProperty('overflow', 'auto');
      setLoading(false);
    }, 4000);
  }, []);

  useEffect(() => {
    if (!loading) {
      setTimeout(() => {setShowOverlay(false)},1000);
    }
  },[loading]);

  return (
    <>
      {showOverlay && <Overlay show={loading}/>}
      <main style={{overflow: loading ? 'hidden' : 'auto'}}>
        <title>Casa Jaguar</title>
        <Header/>
        <Body>
          <Descripcion/>
          <MasterPlan/>
          <Descripcion2/>
        </Body>
        <Footer/>
      </main>

    </>

  )
}

export default IndexPage
