import React from "react";
import Section from "../Section";
import {wrapper, text, image} from './index.module.scss';
import {StaticImage} from "gatsby-plugin-image";

const Descripcion = () => {

  return (
    <div className={wrapper} id="desc1">
      <Section>
        <div className={image}>
          <StaticImage src="../../images/hojas.png" alt="hojas" />
        </div>

        <div className={text}>
          Nos encontramos <b>en el corazón del Delta del Paraná</b>, inmersos en la Naturaleza y a 35 minutos de la Ciudad
          Autónoma de Buenos Aires. En el Km 0 del Bosque Fluvial y frente al Rio Lujan con Puerto y Acceso Propio a las
          venas del Humedal.
          <br/>
          <br/>
          Nos rodea la pureza de lo que se transforma todos los años. De lo añejo, lo incipiente, lo que sigue
          germinando y lo que cultivo la historia, mientras el agua continua su curso.
          <br />
          <b>Somos unicos, es ahora.</b>
        </div>
      </Section>
    </div>
  )

}

export default Descripcion;