import React, {useEffect, useState} from "react";
import {wrapper, centerBox, loadingGif, bar} from './index.module.scss';

const Overlay = ({show}) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress(prevProg => prevProg + 1);
    }, 1000);

    return () => {clearInterval(interval);}
  }, []);

  return (
      <div className={wrapper} style={{opacity: show ? '1' : '0'}}>
        <div className={centerBox}>
          <h1>CASA JAGUAR</h1>
          <div className={loadingGif}>
            <div className={bar} style={{ backgroundColor: progress > 0 ? 'white' : 'transparent'}} />
            <div className={bar} style={{ backgroundColor: progress > 1 ? 'white' : 'transparent'}} />
            <div className={bar} style={{ backgroundColor: progress > 2 ? 'white' : 'transparent'}} />
          </div>
        </div>
      </div>
  )

}

export default Overlay;